<template>
  <UserLayout
    :title="title"
    :showBtn="showButton"
    :buttonText="buttonText"
    @btnClick="onBtnClick"
  >
    <router-view v-if="$route.name !== 'LearningSpacesHome'" />
    <template v-else>
      <p v-if="isLoading"><LxpLoader /></p>
      <template v-else-if="showCreateLearningSpaceView">
        <CreateLearningSpace ref="createSpace" />
      </template>
      <template v-else>
        <SpacesList />
      </template>
    </template>
    <LxpToaster
      :title="toasterTitle"
      :show.sync="showToast"
      :variant="toasterVariant"
      :message="toasterMessage"
    />
  </UserLayout>
</template>

<script>
// components
import UserLayout from "@/components/Layout/UserLayout.vue";
import CreateLearningSpace from "@/components/LearningSpace/CreateLearningSpace.vue";
import SpacesList from "@/components/LearningSpace/SpacesList.vue";
// mixins
import fetchSpaceList from "@/components/Partner/mixins/fetchSpaceList";
import { LxpLoader, LxpToaster } from "didactica";
export default {
  provide: {
    parentRoute: "learningSpace"
  },
  mixins: [fetchSpaceList],

  data() {
    return {
      showToast: false,
      toasterTitle: "",
      toasterMessage: "",
      toasterVariant: ""
    };
  },
  components: {
    UserLayout,
    CreateLearningSpace,
    LxpLoader,
    LxpToaster,
    SpacesList
  },
  computed: {
    learningSpacesCount() {
      return this.getSpacesList.length;
    },
    showCreateLearningSpaceView() {
      return (
        this.learningSpacesCount === 0 ||
        this.$route.name === "CreateInitiative"
      );
    },
    showButton() {
      return this.showCreateLearningSpaceView;
    },
    title() {
      if (this.isLoading || this.$route.name === "EditLearningSpace") {
        return "";
      } else if (this.showCreateLearningSpaceView) {
        return this.$route.name === "CreateInitiative"
          ? "Creating initiative"
          : "Creating learning space";
      } else {
        return this.learningSpacesCount > 1
          ? "Learning Spaces"
          : "Learning Space";
      }
    },
    buttonText() {
      if (this.showCreateLearningSpaceView) {
        return "Save";
      } else {
        return "";
      }
    }
  },
  created() {
    this.init();
    this.toasterRootListeners();
  },

  methods: {
    init() {
      this.isLoading = true;
      this.$store
        .dispatch("getSpacesList")
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.$router.push({
            name: "UnauthorisedAccess"
          });
        });
    },
    toasterRootListeners() {
      this.$root.$on("unPublishLearningSpace", data => {
        this.handleToasterData(data);
      });
      this.$root.$on("createInitiativeSave", data => {
        this.handleToasterData(data);
      });
      this.$root.$on("initiativeDelete", data => {
        this.handleToasterData(data);
      });
      this.$root.$on("PublishInitiative", data => {
        this.handleToasterData(data);
      });
      this.$root.$on("UnpublishInitiative", data => {
        this.handleToasterData(data);
      });
      this.$root.$on("PublishInitiativeError", data => {
        this.handleToasterData(data);
      });
      this.$root.$on("UnpublishInitiativeError", data => {
        this.handleToasterData(data);
      });
    },
    handleToasterData(data) {
      this.toasterTitle = data.title;
      this.toasterMessage = data.message;
      this.toasterVariant = data.variant;
      this.showToast =
        data.unPublished ||
        data.initiativeCreated ||
        data.initiativeDeleted ||
        data.initiativePublished ||
        data.initiativeUnPublished ||
        data.errorPublishingInitiative ||
        data.errorUnpublishingInitiative;
    },
    onBtnClick() {
      if (this.learningSpacesCount === 0) {
        this.$refs["createSpace"].validate().then(res => {
          if (res) {
            this.$refs["createSpace"].submitForm();
          }
        });
      } else if (this.$route.name === "CreateInitiative") {
        this.$root.$emit("InitiativesSubmitForm");
      }
    }
  }
};
</script>
