<template>
  <div
    class="ls-card__wrapper"
    @click="onManageClick"
    tabindex="0"
    @keydown.enter="onManageClick"
  >
    <div class="ls-card__left">
      <img :src="logo" alt="logo" width="128" height="72" />
    </div>
    <div class="ls-card__right">
      <h6>{{ title }}</h6>
      <p>{{ subTitle }}</p>
      <div class="ls-card__right--footer">
        <div
          class="ls-card__right--footer-manage"
          @click="onManageClick"
          tabindex="0"
          @keydown.enter="onManageClick"
        >
          <img src="@/assets/images/learning-space/cards/edit.svg" alt="edit" />
          <span>Manage</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    org: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    onManageClick: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.ls-card__wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  @include horizontal-space-between;
  &:hover {
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
  }
  .ls-card__left {
    img {
      object-fit: contain;
      vertical-align: baseline;
    }
  }
  .ls-card__right {
    padding-left: 16px;
    flex-grow: 1;
    h6 {
      @include line-clamp(1);
      @include body-regular;
    }
    p {
      @include line-clamp(2);
      @include body-medium;
      color: $brand-neutral-300;
      margin-bottom: 12px;
    }
    .ls-card__right--footer {
      display: flex;
      justify-content: flex-end;
      @include label-medium;
      .ls-card__right--footer-manage {
        @include label-medium(500);
        padding: 6px;
        color: $brand-neutral-900;
        cursor: pointer;
        img {
          margin-right: 5px;
        }
        &:hover {
          background: $brand-primary-75;
          border-radius: 8px;
          color: $brand-primary-400;
          img {
            filter: $icon-filter-primary;
          }
        }
      }
    }
  }
  cursor: pointer;
}
</style>
